import { Button } from 'src/design-system'
import { useRailsContext } from 'components/rails-context'
import { ToggleButton } from 'components/toggle-button'
import * as React from 'react'
import { store } from 'store/index'

export type OrgPreferencesFormProps = {
  calledBy: string
  canCheckinAgainstAnyPosition: boolean
  canSelfAssignPosition: boolean
  showPositionLabels: boolean
  showSalaries: boolean
  membershipsVisible: boolean
}

export const OrgPreferencesForm = (props: OrgPreferencesFormProps) => {
  const {
    calledBy,
    canCheckinAgainstAnyPosition: initialCanCheckinAgainstAnyPosition,
    canSelfAssignPosition: initialCanSelfAssignPosition,
    showPositionLabels: initialShowPositionLabels,
    showSalaries: initialShowSalaries,
    membershipsVisible: initialMembershipsVisible,
  } = props

  const [canCheckinAgainstAnyPosition, setCanCheckinAgainstAnyPosition] =
    React.useState(initialCanCheckinAgainstAnyPosition)

  const [canSelfAssignPosition, setCanSelfAssignPosition] = React.useState(
    initialCanSelfAssignPosition
  )

  const [showPositionLabels, setShowPositionLabels] = React.useState(
    initialShowPositionLabels
  )

  const [showSalaries, setShowSalaries] = React.useState(initialShowSalaries)
  const [membershipsVisible, setMembershipsVisible] = React.useState(
    initialMembershipsVisible
  )

  const orgSlug = store.currentUser?.org?.slug

  const { csrfToken } = useRailsContext()

  return (
    <form action={`/orgs/${orgSlug}`} method="post">
      <input type="hidden" value="patch" name="_method" />
      <input type="hidden" value={csrfToken} name="authenticity_token" />
      <input
        type="hidden"
        name="called_by"
        value={calledBy}
        autoComplete="off"
      />

      <h4 className="font-normal text-gray-500 mb-6">User permissions</h4>
      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-2">
          <label className="m-0">
            Allow users to Check-in against any positions
          </label>
          <input
            type="hidden"
            name="org[can_checkin_against_any_position]"
            value={canCheckinAgainstAnyPosition ? '1' : '0'}
          />
          <ToggleButton
            checked={canCheckinAgainstAnyPosition}
            onChange={() => {
              setCanCheckinAgainstAnyPosition(!canCheckinAgainstAnyPosition)
            }}
          />
        </div>
        <p>
          Allow users to choose which position they Check-in against.
          Un-checking this will ensure users always Check-in against their
          current position.
        </p>
      </div>
      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-2">
          <label className="m-0">
            Allow users to self-assign their positions
          </label>
          <input
            type="hidden"
            name="org[can_self_assign_position]"
            value={canSelfAssignPosition ? '1' : '0'}
          />
          <ToggleButton
            checked={canSelfAssignPosition}
            onChange={() => {
              setCanSelfAssignPosition(!canSelfAssignPosition)
            }}
          />
        </div>
        <p>
          When turned on, employees can select their own position from their
          profile. If un-checked, an admin, editor or manager will need to
          assign for them.
        </p>
      </div>

      <h4 className="font-normal text-gray-500 mb-6">Feature Visibility</h4>
      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-2">
          <label className="m-0">Show position labels</label>
          <input
            type="hidden"
            name="org[show_position_labels]"
            value={showPositionLabels ? '1' : '0'}
          />
          <ToggleButton
            checked={showPositionLabels}
            onChange={() => {
              setShowPositionLabels(!showPositionLabels)
            }}
          />
        </div>
        <p>When turned on, position labels will be visible in all teams.</p>
      </div>
      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-2">
          <label className="m-0">Show salaries</label>
          <input
            type="hidden"
            name="org[show_salaries]"
            value={showSalaries ? '1' : '0'}
          />
          <ToggleButton
            checked={showSalaries}
            onChange={() => {
              setShowSalaries(!showSalaries)
            }}
          />
        </div>
        <p>
          When turned on, salary bands will be visible in to all users in all
          teams. When turned off, salaries will only be visible to admins.
        </p>
      </div>

      <div className="mb-6">
        <div className="flex flex-row items-center justify-between mb-2">
          <label className="m-0">
            Allow all users to see other users in position?
          </label>
          <input
            type="hidden"
            name="org[memberships_visible]"
            value={membershipsVisible ? '1' : '0'}
          />
          <ToggleButton
            checked={membershipsVisible}
            onChange={() => {
              setMembershipsVisible(!membershipsVisible)
            }}
          />
        </div>
        <p>
          When turned on, all users can see all other users in a position. When
          turned off, admins can see all users, managers see their reporting
          line, and editors see users in their team.
        </p>
      </div>

      <Button type="submit">Save Settings</Button>
    </form>
  )
}
