import * as React from 'react'
import { SettingsBreadcrumbs } from '../settings-breadcrumbs'
import { SettingsTitle } from 'components/settings-title'
import { observer } from 'mobx-react-lite'
import { OrgPreferencesForm } from './form'

export type OrgPreferencesPageProps = {
  calledBy: string
  organisationString: string
  canCheckinAgainstAnyPosition: boolean
  canSelfAssignPosition: boolean
  showPositionLabels: boolean
  showSalaries: boolean
  membershipsVisible: boolean
}

export const OrgPreferencesPage = observer((props: OrgPreferencesPageProps) => {
  const {
    calledBy,
    organisationString,
    canCheckinAgainstAnyPosition,
    canSelfAssignPosition,
    showPositionLabels,
    showSalaries,
    membershipsVisible,
  } = props

  return (
    <div className="pb-16">
      <SettingsBreadcrumbs
        breadcrumbs={[organisationString, 'Org preferences']}
      />
      <SettingsTitle>Org preferences</SettingsTitle>

      <OrgPreferencesForm
        calledBy={calledBy}
        canCheckinAgainstAnyPosition={canCheckinAgainstAnyPosition}
        canSelfAssignPosition={canSelfAssignPosition}
        showPositionLabels={showPositionLabels}
        showSalaries={showSalaries}
        membershipsVisible={membershipsVisible}
      />
    </div>
  )
})
